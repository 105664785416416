@charset "UTF-8";
html {
  text-align: center;
}

@font-face {
  font-family: "RougeScript";
  src: url("/public/font-family/RougeScript-Regular.ttf");
}
@font-face {
  font-family: "DancingScript";
  src: url("/public/font-family/DancingScript-VariableFont_wght.ttf");
}
body {
  background: #eeeeee;
  font-family: "Segoe UI", "Calibri", "OpenSans";
  margin: 0 auto;
  font-size: 1.1rem;
}

body a {
  color: #1bb4bb;
}

.main-content {
  border: #ffffff;
  border-radius: 60px;
  padding-right: 20%;
  padding-left: 20%;
  margin: auto;
}

@media screen and (max-width: 1200px) {
  .main-content {
    padding-left: 5%;
    padding-right: 5%;
  }
}
@media screen and (max-width: 920px) {
  .main-content {
    padding-left: 2%;
    padding-right: 2%;
  }
  #slides {
    max-width: 7600px;
    margin: auto;
    border: unset;
  }
  .slide-img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    object-position: center;
    border-radius: 13px;
  }
}
@media screen and (max-width: 720px) {
  .slide-img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    object-position: center;
    border-radius: 13px;
  }
}
/* TEXTE */
.padding-bot-15 {
  padding-bottom: 15px;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.fs-subtitle .left {
  text-align: left;
}

.right {
  text-align: right;
  padding: 2%;
}

.right img {
  width: 75px;
}

.main-top .title {
  color: #1bb4bb;
  font-family: "RougeScript";
  font-size: 60px;
  text-align: center;
  padding: 0%;
  margin: 0 auto;
  left: 0;
  right: 0;
  vertical-align: auto;
}

.texte {
  padding-top: 20px;
  color: #393e46;
}

.justifier {
  text-align: justify;
}

hr {
  margin-top: 5%;
}

/* NAV */
.topnav {
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.topnav ul {
  text-align: center;
  background: #afdddf;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1), inset 0 0 1px rgba(255, 255, 255, 0.6);
}

.topnav ul li {
  display: inline-block;
}

.topnav ul li a {
  padding: 18px;
  text-transform: uppercase;
  color: #393e46;
  font-size: 18px;
  text-decoration: none;
  display: block;
  float: left;
}

.topnav ul li a:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1), inset 0 0 1px rgba(255, 255, 255, 0.6);
  background-color: #1bb4bb;
  color: rgba(235, 235, 235, 0.7);
  text-decoration: bold;
}

.no-decoration {
  text-decoration: none;
}

.icons-header {
  width: 24px;
}

.topnav .icon {
  display: none;
}

.main-top {
  text-align: center;
}

.slide-container .next,
.slide-container .prev {
  color: #E6E9E6;
}

.next:hover,
.prev:hover {
  color: #E6E9E6;
  background-color: #111111;
}

a:not([href]):hover {
  color: #E6E9E6;
}

@media screen and (max-width: 990px) {
  .logo {
    display: none;
  }
  .title {
    position: relative;
  }
  .topnav ul li a {
    display: none;
  }
  .topnav ul li {
    display: block;
    padding-right: 0;
  }
  .topnav a.icon {
    font-size: xx-large;
    display: block;
    padding-right: 5%;
    text-align: left;
  }
  .topnav.responsive {
    position: relative;
  }
  .topnav.responsive .icon {
    position: absolute;
  }
  .topnav.responsive li a {
    float: none;
    display: inline-block;
    text-align: center;
  }
}
/*----*/
/* ACCUEIL */
.intro {
  text-shadow: 0 0 0.2em #4E3C80, 0 0 0.2em #FFFFFF;
  box-shadow: 5px 5px 5px 5px rgb(45, 58, 138);
  border: 2px solid #2a3cad;
  margin-bottom: 25px;
  text-align: center;
}

/* SLIDES */
.custom-slider {
  display: none;
}

.slide-container {
  max-width: 800px;
  position: relative;
  margin: auto;
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #E6E9EA;
  font-size: 30px;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.6s ease;
}

.prev {
  left: 15px;
}

.next {
  right: 15px;
}

.slide-text {
  position: absolute;
  color: #ffffff;
  font-size: 15px;
  padding: 15px;
  bottom: 15px;
  width: 100%;
  text-align: center;
}

.slide-text-dark {
  position: absolute;
  color: #76396B;
  font-weight: bolder;
  font-size: 15px;
  padding: 15px;
  bottom: 15px;
  width: 100%;
  text-align: center;
}

.slide-index {
  color: #ffffff;
  font-size: 13px;
  padding: 15px;
  position: absolute;
  top: 0;
}

.slide-img {
  width: 100%;
  height: 450px;
  object-fit: cover;
  object-position: center;
  border-radius: 13px;
}

.slide-dot {
  text-align: center;
}

.dot {
  cursor: pointer;
  height: 10px;
  width: 10px;
  margin: 0 2px;
  background-color: #999999;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active,
.dot:hover {
  background-color: #111111;
}

.fade {
  animation-name: fade;
  animation-duration: 1s;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* HEADER */
.logo {
  padding: 15px;
  vertical-align: middle;
  width: 15%;
}

/* FOOTER */
hr.style-eight {
  overflow: visible;
  /* For IE */
  padding: 0;
  border: none;
  border-top: medium double #333;
  color: #333;
  text-align: center;
}

hr.style-eight:after {
  content: "§";
  display: inline-block;
  position: relative;
  top: -0.7em;
  font-size: 1.5em;
  padding: 0 0.25em;
  background: #eeeeee;
}

.footer {
  text-align: center;
}

#wrapper {
  font-style: normal;
  display: block;
  padding-top: 25px;
}

.pad {
  padding: 25px;
}

.button {
  text-decoration: none;
  color: #393e46;
  font-size: 20px;
  position: relative;
  padding: 10px;
}

a.button.one {
  text-decoration: none;
  color: #393e46;
  font-size: 16px;
}

a.button.one:before,
a.button.one:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  transition: all 0.3s ease;
}

a.button.one:before {
  top: -2.5%;
  left: -1%;
  border-top: 2px solid #afdddf;
  border-left: 2px solid #afdddf;
}

a.button.one:after {
  bottom: -2.5%;
  right: -1%;
  border-bottom: 2px solid #1bb4bb;
  border-right: 2px solid #1bb4bb;
}

a.button.one:hover:before,
a.button.one:hover:after {
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
}

.infos {
  text-shadow: 0 0 0.2em #1bb4bb, 0 0 0.2em #FFFFFF;
}

.liens-footer {
  padding: 15px;
}

.partenaire {
  padding: 15px;
}

/* button back to top */
.scrollToTop img {
  width: 100px;
  height: 130px;
  padding: 10px;
  text-align: center;
  background: transparent;
  font-weight: bold;
  text-decoration: none;
  position: fixed;
  bottom: 75px;
  right: 40px;
  display: block;
}

.scrollToTop {
  padding: 10px;
  text-align: center;
  background: transparent;
  font-weight: bold;
  text-decoration: none;
  position: fixed;
  bottom: 75px;
  right: 40px;
  display: block;
  z-index: 20;
}

.scrollToTop .top {
  padding-top: 90px;
  width: 100px;
  height: 150px;
}